import React from "react";
const ContactUsButton = () => {
  const redirect = () => {
    window.location.href = "/contact-us";
  };
  return (
    <button
      onClick={redirect}
      className="bg-orange text-white rounded-md text-sm lg:px-10 sm:px-8 sm:py-4 md:px-8 md:py-4 font-bold hover:bg-[#ff782a] duration-200"
    >
      Contact Us
    </button>
  );
};

export default ContactUsButton;
