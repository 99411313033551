import ContactUsButtonLight from "./ContactUsButtonLight";
import Logo from "./../assets/images/logo.png";
import ContactUsButton from "./ContactUsButton";
import BackgroundImage from "../assets/images/bg-wave-orange.png";
import BgWave from "../assets/images/bg-wave-orange.png";

function FooterLight() {
  return (
    <div>
      <div id="footer-light" className="mt-72">
        <div
          className="flex lg:flex-row lg:justify-between lg:py-10 lg:px-24
                md:flex-col md:gap-4 md:py-6 md:px-12 md:justify-center
                sm:flex-col sm: gap-4 sm:py-4 sm:px-6 sm: justify-center
                align-middle items-center"
        >
          <div
            className="flex flex-col gap-4 
                    lg:justify-start lg:items-start
                    md:justify-center md:items-center
                    sm:justify-center sm:items-center"
          >
            <img src={Logo} alt="Logo" className="lg:w-40 sm:w-24 md:w-36" />
            <p
              className="lg:w-2/3 orange text-md 
                        lg:justify-start lg:text-left
                        md:justify-center md:text-center md:w-full
                        sm:justify-center sm:text-center sm:w-full"
              style={{ fontFamily: "Red Hat Display Regular" }}
            >
              Bringing CONSOLE to crisis social media communications.
            </p>
          </div>
          <div>
            <ContactUsButton />
          </div>
        </div>
      </div>
      <div className="bg-orange py-4 flex flex-col lg:flex-row justify-between items-center px-6 lg:px-24">
        <p className="text-white text-sm mb-2 lg:mb-0">
          © 2025 The Console-D Team. All Rights Reserved
        </p>
        <div className="flex gap-6">
          <a
            href="https://socium.sg/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-sm hover:underline"
          >
            Terms & Conditions
          </a>
          <a
            href="https://socium.sg/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-sm hover:underline"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default FooterLight;
