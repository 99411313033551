import React, { useContext, useState, useRef, useEffect } from "react";
import UserContext from "../context/UserContext";
import "../pages/css/Main.css";
import { UserIcon } from "@heroicons/react/outline";
import LogoutButton from "./LogoutButton";

const UserWidget = () => {
  const { user, loading } = useContext(UserContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading || !user) {
    return null;
  }

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="bg-orange-100 hover:bg-orange-200 transition-colors duration-200 rounded-md py-2 px-4 flex items-center space-x-2 focus:outline-none focus:ring-2 focus:ring-orange-300"
      >
        <UserIcon className="w-5 h-5 text-orange-500" />
        <span className="text-sm font-semibold text-orange-700">
          {user.firstname} {user.lastname}
        </span>
      </button>
      {dropdownVisible && (
        <div className="absolute right-0 mt-2 w-56 bg-white border border-gray-200 rounded-md shadow-lg z-10 transition-all duration-200 ease-in-out transform origin-top-right">
          <div className="py-1">
            <a
              href="/profile-page"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-100 hover:text-orange-700 transition-colors duration-150"
            >
              Profile
            </a>
            <a
              href="/account-settings"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-100 hover:text-orange-700 transition-colors duration-150"
            >
              Account Settings
            </a>
            <a
              href="/subscription-plan"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-orange-100 hover:text-orange-700 transition-colors duration-150"
            >
              Subscription Plan
            </a>
            <div className="border-t border-gray-200 my-1"></div>
            <div className="block px-4 py-2 text-center w-full text-sm text-red-600 hover:bg-red-100 hover:text-red-700 transition-colors duration-150">
              <LogoutButton />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserWidget;
