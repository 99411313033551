import React, { useContext, useEffect, useState } from "react";
import "../../pages/css/Main.css";
import api from "../../AxiosConfig";
import UserContext from "../../context/UserContext";
import NotSubscribedWidget from "../../widget/NotSubscribedWidget";
import SubscriptionPlanSkeleton from "../../loading/SubscriptionPlanSkeleton";
import MainLayout from "../../layouts/MainLayout";

const SubscriptionPlanPage = () => {
  console.log("SubscriptionPlanPage component mounted");

  const { user, loading: userLoading } = useContext(UserContext);
  console.log("UserContext:", { user, userLoading });

  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        // console.log("Fetching subscription data...");
        const token = localStorage.getItem("jwtToken");
        // console.log("Token:", token);

        const response = await api.get("/get-subscription", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        setSubscription(response.data.message);
        // Add console logs here
        console.log("subscription data:", response);
      } catch (error) {
        console.error("There was an error!", error);
        setError("Failed to fetch subscription data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);
    const localDateObj = new Date(
      dateObj.getTime() + dateObj.getTimezoneOffset() * 60000
    );
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = localDateObj.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  if (isLoading) {
    return (
      <p>
        <SubscriptionPlanSkeleton />
      </p>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <MainLayout>
      <div className="py-2 sm:px-4">
        <div className="lg:px-12 lg:mt-12 md:mt-8 sm:px-4 md:px-6">
          <p
            style={{ fontFamily: "Red Hat Display" }}
            className="mb-8 lg:text-3xl md:text-2xl sm:text-2xl"
          >
            Account
          </p>
          {user.role_name === "free user" && <NotSubscribedWidget />}
          <div>
            <div className="lg:flex lg:flex-row md:flex md:flex-row sm:flex sm:flex-col lg:gap-24 md:gap-16 lg:mt-8 md:mt-8">
              <div className="gap-4 lg:flex lg:flex-col md:flex md:flex-col sm:flex sm:flex-row">
                <a href="/profile-page">
                  <p className="font-bold text-gray-400 lg:text-lg md:text-md sm:text-sm">
                    Profile
                  </p>
                </a>
                <a href="/account-settings">
                  <p className="font-bold text-gray-400 lg:text-lg md:text-md sm:text-sm">
                    Account Settings
                  </p>
                </a>
                <a href="/subscription-plan">
                  <p className="font-bold lg:text-lg md:text-md sm:text-sm text-md orange">
                    Subscription Plan
                  </p>
                </a>
              </div>

              <div className="flex flex-col gap-8">
                <p
                  style={{ fontFamily: "Red Hat Display" }}
                  className="lg:text-2xl md:text-xl sm:text-lg sm:mt-8"
                >
                  Your Current Plan
                </p>
                <div className="flex flex-col w-full max-w-lg gap-4 px-6 py-8 bg-white border-2 border-orange-600 rounded-lg">
                  <div className="flex flex-row justify-between">
                    <p
                      className="lg:text-2xl md:text-xl sm:text-lg orange"
                      style={{ fontFamily: "Red Hat Display" }}
                    >
                      {subscription["subscription_name"]}
                    </p>
                    <div className="flex flex-col items-center">
                      <p className="lg:text-lg md:text-lg sm:text-md">
                        <b>
                          {subscription["currency"]} {subscription["price"]}
                        </b>
                      </p>
                      <p className="justify-center text-center align-middle lg:text-xs md:text-xs sm:text-xs orange w-36">
                        One-time subscription till 30 June 2025
                      </p>
                    </div>
                  </div>
                  <p className="lg:text-sm md:text-sm sm:text-xs">
                    {subscription["description"]}
                  </p>
                  <div className="flex flex-row gap-8">
                    <div className="flex flex-col gap-2">
                      <p className="lg:text-sm md:text-sm">
                        <b>Subscribed on:</b>
                      </p>
                      <p className="lg:text-sm md:text-sm sm:text-xs">
                        {formatDate(subscription["joined_at"])}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="lg:text-sm md:text-sm">
                        <b>End date:</b>
                      </p>
                      <p className="lg:text-sm md:text-sm sm:text-xs">
                        {formatDate(subscription["end_date"])}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default SubscriptionPlanPage;
