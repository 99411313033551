import "../../pages/fonts.css";
import "../../pages/css/Main.css";
import { useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { UserRegisterRequestDTO } from "../../models/User";
import { register } from "../../services/UserService";
import { FiInfo } from "react-icons/fi";
import BaseOutput from "../../models/output/BaseOutput";
import Logo from "../../widget/Logo";
import ContactUsButton from "../../widget/ContactUsButton";
import FooterLight from "../../widget/FooterLight";

const RegisterPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState(new UserRegisterRequestDTO());
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessage("");
  };
  const saveToken = (token) => {
    localStorage.setItem("jwtToken", token);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Password validation regex
    const passwordRegex = /^(?=.*[A-Z])(?=.*\W).{6,}$/;

    if (
      !formData.email ||
      !formData.password ||
      !formData.firstname ||
      !formData.lastname ||
      !formData.confirmPassword
    ) {
      setErrorMessage("All fields must be filled out.");
      return;
    }

    if (!passwordRegex.test(formData.password)) {
      setErrorMessage(
        "Password must be at least 6 characters long, contain at least one special character, and one capital letter."
      );
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    const response = await register(formData);
    const output = new BaseOutput(response.message, response.status_code);
    if (output.status_code === 200) {
      saveToken(response["access_token"]);
      window.location.href = "/verify-account";
    } else {
      setErrorMessage(output.message);
    }
  };

  const ErrorMessage = ({ message }) => {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 text-xs px-4 py-3 rounded relative flex flex-row gap-2"
        role="alert"
      >
        <FiInfo className="inline-block align-middle mr-2" />
        <span className="inline-block align-middle">{message}</span>
      </div>
    );
  };

  return (
    <div>
      <div className="page-container relative h-screen py-2 px-4">
        <div className="lg:px-12 lg:py-6 sm:p-4 md:p-8 flex flex-row justify-between">
          <Logo />
        </div>
        <div className="flex flex-col sm:gap-6 md:flex-row md:items-center lg:px-32  sm:px-6  md:px-12">
          <div className="flex flex-col gap-2 md:w-2/3 md:mt-56">
            <p
              className="text-3xl mt-auto md:w-4/5"
              style={{ fontFamily: "Red Hat Display" }}
            >
              Amplify the right messages in a{" "}
              <span className="orange">crisis</span>
            </p>
            <p className="text-base md:w-4/5">
              Bringing CONSOLE to crisis social media communications.
            </p>
          </div>

          <div className="md:w-2/3 md:mt-0 sm:px-8 md:px-16">
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <p
                  style={{ fontFamily: "Red Hat Display" }}
                  className="text-2xl"
                >
                  Register
                </p>
                <p className="text-sm orange underline">
                  <a href="/login">Already have an account? Login here.</a>
                </p>
              </div>
              <div className="flex flex-col gap-2">
                {errorMessage && <ErrorMessage message={errorMessage} />}

                <div className="flex flex-col gap-2 mt-2">
                  <div className="flex flex-row gap-4">
                    <div className="w-1/2 flex flex-col gap-2">
                      <p className="text-sm">First Name</p>
                      <input
                        className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
                        type="text"
                        placeholder="John"
                        name="firstname"
                        value={formData.firstname}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-1/2 flex flex-col gap-2">
                      <p className="text-sm">Last Name</p>
                      <input
                        className="mb-2 px-4 py-2 border border-gray-300 rounded-md"
                        type="text"
                        placeholder="Doe"
                        name="lastname"
                        value={formData.lastname}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <p className="text-sm">Email</p>
                  <input
                    className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md"
                    type="email"
                    placeholder="john@email.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <p className="text-sm">Password</p>
                  <div className="relative flex items-center">
                    <input
                      id="password"
                      className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md pr-10"
                      type={showPassword ? "text" : "password"}
                      placeholder="********"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center mr-2 mb-2 orange "
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <EyeOffIcon className="h-5 w-5" />
                      ) : (
                        <EyeIcon className="h-5 w-5 " />
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <p className="text-sm">Confirm Password</p>
                  <div className="relative flex items-center">
                    <input
                      id="password"
                      className="w-full mb-2 px-4 py-2 border border-gray-300 rounded-md pr-10"
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="********"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center mr-2 mb-2 orange "
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <EyeOffIcon className="h-5 w-5" />
                      ) : (
                        <EyeIcon className="h-5 w-5 " />
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex justify-center mt-6">
                  <button
                    className="sm:w-32 bg-orange px-4 py-2 hover:bg-[#ff782a] duration-200 text-white text-sm rounded-md"
                    type="submit"
                  >
                    Register
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="background-image">
        <FooterLight />
      </div>
    </div>
  );
};

export default RegisterPage;
